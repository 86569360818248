<template>
  <Page>
    <div v-if="!isMobile || !showMessageDetail">
      <ViewTitle :title="labels.Title" class="message-title" />
      <!-- <h2 class="display-none-heading">{{ labels.Title }}</h2> -->
      <p class="description">{{ labels.Description }}</p>
    </div>
    <div v-if="isMobile && !showMessageDetail">
      <div class="mobile-filter-toggle">
        <a
          href="#"
          @click="toggle_mobilecontent()"
          v-bind:aria-expanded="this.mobileToggleOpen"
          class="pr-2"
        >
          <span v-if="this.mobileToggleOpen === true"
            >{{ labels.FilterClose }}&nbsp;</span
          >
          <span v-if="this.mobileToggleOpen === false"
            >{{ labels.FilterOpen }}&nbsp;</span
          >

          <b-icon
            v-if="this.mobileToggleOpen === true"
            icon="chevron-up"
          ></b-icon>
          <b-icon
            v-if="this.mobileToggleOpen === false"
            icon="chevron-down"
          ></b-icon>
        </a>
      </div>
      <div v-if="mobileToggleOpen">
        <ToggleMobile :allIsOpen="allIsOpen" v-if="!isArchivePage">
          <template v-slot:title>
            <div
              class="filter-header-text"
              :class="{ onHoverColText: isMobileSerivceType == true }"
            >
              <IconSet
                :class="{ onHoverColIcon: isMobileSerivceType == true }"
                icon="messagetype"
              ></IconSet
              ><span>{{ labels.Art }}</span>
            </div>
          </template>
          <template v-slot:content>
            <div class="container1">
              <div class="filterRow">
                <div class="vcs-column col-md-3 group line highlight">
                  <div class="vcs-check-filter">
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-checkbox-group
                        v-model="selectedItem"
                        :aria-describedby="ariaDescribedby"
                        name="messageType"
                      >
                        <b-form-checkbox
                          v-for="(messageType, index) in messageTypes"
                          :key="index"
                          :value="messageType"
                        >
                          {{ messageType.Name }}
                        </b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ToggleMobile>

        <ToggleMobile
          mode="line"
          :id="'filter_section'"
          group="filter"
          :btnlabel="labels.FilterOpen"
          :btnlabelclose="labels.FilterClose"
          css="filter mb-4"
          ref="filter_toggle"
          activateinfo
          :allIsOpen="allIsOpen"
        >
          <template v-slot:title>
            <div
              class="filter-header-text"
              :class="{ onHoverColText: isMobileStreet === true }"
            >
              <IconSet
                icon="category"
                :class="{ onHoverColIcon: isMobileStreet === true }"
              >
              </IconSet>
              <span>{{ labels.Category }}</span>
            </div>
          </template>
          <template v-slot:content>
            <div class="container1">
              <div class="filterRow">
                <div class="vcs-column col-md-3 address group line highlight">
                  <div class="vcs-check-filter">
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-checkbox-group
                        v-model="selectedItem"
                        :aria-describedby="ariaDescribedby"
                        name="address"
                      >
                        <b-form-checkbox
                          v-for="(category, index) in serviceGroupCollection"
                          :key="index"
                          :value="category"
                        >
                          <div class="vcf-title">{{ category.Name }}</div>
                        </b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ToggleMobile>

        <ToggleMobile :allIsOpen="allIsOpen" v-if="isArchivePage">
          <template v-slot:title>
            <div
              class="filter-header-text"
              :class="{ onHoverColText: isMobileDate == true }"
            >
              <IconSet
                :class="{ onHoverColIcon: isMobileDate == true }"
                icon="calendar"
              ></IconSet
              ><span>{{ labels.Date }}</span>
            </div>
          </template>
          <template v-slot:content>
            <div class="container1">
              <div class="filterRow">
                <div class="vcs-column col-md-3 group timespan line highlight">
                  <div class="vcs-check-filter">
                    <div class="vcf-title input-set">
                      <span class="cft-title mt-0">{{ labels.From }}</span>
                    </div>
                    <div class="vcf-input">
                      <b-form-datepicker
                        class="vcs-date-box"
                        size="sm"
                        :min="defaultSelectedDate.From"
                        :max="maxDate"
                        :locale="get_language()"
                        :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }"
                        v-model="fromDate"
                      ></b-form-datepicker>
                    </div>
                  </div>
                  <div class="vcs-check-filter">
                    <div class="vcf-title input-set">
                      <span class="cft-title mt-0">{{ labels.To }}</span>
                    </div>
                    <div class="vcf-input">
                      <b-form-datepicker
                        class="vcs-date-box"
                        size="sm"
                        :locale="get_language()"
                        :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }"
                        :min="minDate"
                        :max="defaultSelectedDate.To"
                        v-model="toDate"
                      ></b-form-datepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ToggleMobile>

        <ToggleFilterButton>
          <template v-slot:content>
            <div class="container1">
              <div class="filterRow">
                <div class="vcs-column filter-section pb-2">
                  <div class="vcs-filter-section" v-if="selectedItem.length">
                    <span v-for="(item, index) in selectedItem" :key="index">
                      <span class="vfi-link"
                        ><span
                          class="fil-mark"
                          @click="removeSelectedItem(item, index)"
                          >x</span
                        >&nbsp;{{
                          item.Name
                            ? item.Name
                            : `${get_formatted_date(
                                item.from,
                                "DD.MM.YYYY"
                              )} - ${get_formatted_date(item.to, "DD.MM.YYYY")}`
                        }}</span
                      >
                    </span>
                  </div>

                  <div>
                    <div
                      class="vcs-column address text-center pr-0 ClearButton"
                      @click="clearFilter"
                    >
                      <IconSet icon="filter"></IconSet>{{ labels.FilterClear }}
                    </div>
                    <!-- <b-button
                      variant="primary"
                      class="w-100 p-2 rounded vgs-form-button filter-button"
                      @click="onFilterHandler"
                      >{{ labels.FilterBtnText }}
                    </b-button> -->
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ToggleFilterButton>
      </div>
    </div>
    <div v-else-if="!isMobile">
      <Toggle
        mode="filter-line"
        :id="'filter_section'"
        group="filter"
        :btnlabel="labels.FilterOpen"
        :btnlabelclose="labels.FilterClose"
        css="filter"
        ref="filter_toggle"
        activateinfo
      >
        <template v-slot:title>
          <div class="container1">
            <div class="row">
              <div
                class="vcs-column art col-md-4 line"
                :class="{ onHoverColText: isHover === 'messagetype' }"
                v-if="!isArchivePage"
              >
                <IconSet
                  :class="{ onHoverColIcon: isHover === 'messagetype' }"
                  icon="messagetype"
                ></IconSet
                >{{ labels.Art }}
              </div>
              <div
                class="vcs-column category col-md-4 line"
                :class="{ onHoverColText: isHover === 'category' }"
              >
                <IconSet
                  :class="{ onHoverColIcon: isHover === 'category' }"
                  icon="category"
                ></IconSet
                >{{ labels.Category }}
              </div>
              <div
                class="vcs-column group col-md-4 line"
                :class="{ onHoverColText: isHover === 'calendar' }"
                v-if="isArchivePage"
              >
                <IconSet
                  :class="{ onHoverColIcon: isHover === 'calendar' }"
                  icon="calendar"
                ></IconSet
                >{{ labels.Date }}
              </div>
            </div>
          </div>
        </template>
        <template v-slot:content>
          <div class="container1">
            <div class="filterRow">
              <div
                class="vcs-column col-md-3 address line highlight"
                @mouseover="isHover = 'messagetype'"
                @mouseleave="isHover = ''"
                v-if="!isArchivePage"
              >
                <div class="vcs-check-filter">
                  <b-form-group v-slot="{ ariaDescribedby }" label=" ">
                    <b-form-checkbox-group
                      v-model="selectedItem"
                      value-field="address"
                      text-field="name"
                      :aria-describedby="ariaDescribedby"
                      name="address"
                      label=" "
                    >
                      <b-form-checkbox
                        v-for="(messageType, index) in messageTypes"
                        :key="index"
                        :value="messageType"
                      >
                        {{ messageType.Name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>

              <div
                class="vcs-column col-md-3 group line highlight"
                @mouseover="isHover = 'category'"
                @mouseleave="isHover = ''"
              >
                <div class="vcs-check-filter">
                  <b-form-group v-slot="{ ariaDescribedby }" label=" ">
                    <b-form-checkbox-group
                      v-model="selectedItem"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-2"
                    >
                      <b-form-checkbox
                        v-for="(category, index) in serviceGroupCollection"
                        :key="index"
                        :value="category"
                      >
                        {{ category.Name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
              <div
                class="vcs-column col-md-3 timespan line highlight"
                @mouseover="isHover = 'calendar'"
                @mouseleave="isHover = ''"
                v-if="isArchivePage"
              >
                <div class="vcs-check-filter">
                  <div class="vcf-title input-set">
                    <span class="cft-title">{{ labels.From }}</span>
                  </div>
                  <div class="vcf-input">
                    <!-- <label for="from-datepicker" class="d-none">TT.MM.JJJJ</label> -->
                    <b-form-datepicker
                      class="vcs-date-box"
                      size="sm"
                      :locale="get_language()"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      :min="defaultSelectedDate.From"
                      :max="maxDate"
                      v-model="fromDate"
                    ></b-form-datepicker>
                  </div>
                  <!-- <input type="text" value="" placeholder="" name="from-datepicker" id="from-datepicker" class="d-none"> -->
                </div>

                <div class="vcs-check-filter">
                  <div class="vcf-title input-set">
                    <span class="cft-title">{{ labels.To }}</span>
                  </div>
                  <div class="vcf-input">
                    <!-- <label for="to-datepicker" class="d-none">TT.MM.JJJJ</label> -->
                    <b-form-datepicker
                      class="vcs-date-box"
                      size="sm"
                      :locale="get_language()"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      :min="minDate"
                      :max="defaultSelectedDate.To"
                      v-model="toDate"
                    ></b-form-datepicker>
                  </div>
                  <!-- <input type="text" value="" placeholder="" name="to-datepicker" id="to-datepicker" class="d-none"> -->
                </div>
              </div>
              <div class="vcs-column col-md-3"></div>
              <div class="vcs-column col-md-3 filter-section pb-2">
                <div class="vcs-filter-section">
                  <span v-for="(item, index) in selectedItem" :key="index">
                    <span class="vfi-link"
                      ><span
                        class="fil-mark"
                        @click="removeSelectedItem(item, index)"
                        >x</span
                      >&nbsp;{{
                        item.Name
                          ? item.Name
                          : `${get_formatted_date(
                              item.from,
                              "DD.MM.YYYY"
                            )} - ${get_formatted_date(item.to, "DD.MM.YYYY")}`
                      }}</span
                    >
                  </span>
                </div>
                <!-- clear filter icon -->
                <div>
                  <div
                    class="vcs-column address text-right ClearButton"
                    @click="clearFilter"
                  >
                    <IconSet icon="filter"></IconSet>{{ labels.FilterClear}}
                  </div>
                
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:info>
          <div class="vcs-filter-info d-flex align-items-center">
            <span class="vfi-title">{{ labels.AppliedFilter }}:</span>
            <span v-for="(item, index) in selectedItem" :key="index">
              <span class="vfi-link"
                ><span class="fil-mark" @click="removeSelectedItem(item, index)"
                  >x</span
                >&nbsp;{{
                  item.Name
                    ? item.Name
                    : `${get_formatted_date(
                        item.from,
                        "DD.MM.YYYY"
                      )} - ${get_formatted_date(item.to, "DD.MM.YYYY")}`
                }}</span
              >
            </span>
          </div>
        </template>
      </Toggle>
    </div>

   <div class="outerdiv" v-if="filterList.length && isListUpdated">
      <div class="left-column" v-if="!isMobile || !showMessageDetail">
        <div class="search-container">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              class="searchInput"
              :placeholder="labels.Search"
              v-model="searchQuery"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="message-list-container">
          <div
            class="message-item-wrapper"
            :class="
               selectedMessage.UUID == message.messages.UUID ? 'active' : ''
            "
            v-for="(message, index) of filteredMessageList"
            :key="index"
          >
            <div
              v-if="message.messages.MessageStatus.Read == false"
              class="message-item-unread"
            >
              <svg width="6" height="6">
                <circle cx="3" cy="3" r="3" fill="#ffde00" />
              </svg>
            </div>
            <div class="message-item-inner" @click="getMessageDetails(message)">
              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="message-item-title"
                  :class="
                    message.messages.MessageStatus.Read
                      ? ''
                      : 'font-weight-bold'
                  "
                  >{{ message.messages.MessageTitle }}</span
                >
                <span class="message-item-date">{{
                  get_formatted_date(message.messages.MessageDate, "DD.MM.YYYY")
                }}</span>
              </div>
              <div
                class="d-flex justify-content-between align-items-center message-item-description"
              >
                <p class="normal-text">{{ message.messages.MessageSender }}</p>
                <div class="icon-class">
                  <IconSet
                   :icon="message.messages.ServiceTypeGroup.Icon"
                   :fill="'#697982'"
                   :height="'15'"
                   :width="'15'">
                </IconSet>
                  <IconSet
                  class="ml-1"
                    icon="downloaded"
                    v-if="
                      message.messages.MessageAttachment.length &&
                      checkDownload(message.messages.MessageAttachment)
                    "
                  >
                  </IconSet>
                  <IconSet
                    v-else-if="
                      message.messages.MessageAttachment.length &&
                      !checkDownload(message.messages.MessageAttachment)
                    "
                    icon="attachment"
                  >
                  </IconSet>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="message-list-back" v-if="isMobile && showMessageDetail">
        <div @click="switchMessageDetail">
          <b-icon-chevron-left></b-icon-chevron-left> {{labels.BackToList}}
        </div>
      </div>
      <div
        class="right-column"
        v-if="showMessageDetail && messageDetail.MessageTitle && filteredMessageList.length"
      >
        <div>
          <div class="d-flex justify-content-between message-details">
            <div class="d-flex">
              <div>
                <img
                 :src="
                            messageDetail.Source.Emblem
                              ? get_asset_image(`emblems/${messageDetail.Source.Emblem}`)
                              : get_asset_image('emblems/villach.png')
                          "
                  alt="villach"
                  class="message-details-image"
                />
              </div>
              <div class="message-info">
                <span class="pdiv1">{{ messageDetail.MessageSender }}</span>
                <p class="pdiv2 font-weight-bold">
                  {{ messageDetail.MessageTitle }}
                </p>
              </div>
            </div>
            <div class="message-right-info">
              <div class="message-right">
                <span class="message-ref-number"
                  >{{labels.Referencenumber}}: {{ messageDetail.MessageReference }}
                </span>
                <span>{{
                  get_formatted_date(messageDetail.MessageDate, "DD.MM.YYYY")
                }}</span>
              </div>
              <div
                class="d-flex justify-content-end message-right-info-detail"
                v-if="messageDetail.MessageAttachment.length"
              >
                
                <span>{{messageDetail.MessageAttachment.length}} {{messageDetail.MessageAttachment[0].FileType}} {{labels.Info}}</span>
                <IconSet icon="attachment"> </IconSet>
              </div>
            </div>
          </div>
          <hr class="m-0" />
          <div class="message-description" v-if="messageDetail.MessageText">
            <p v-html="messageDetail.MessageText"></p>
          </div>
          <div
            class="message-attachment-wrapper"
            v-if="messageDetail.MessageAttachment.length"
          >
            <div
              class="message-attachment"
              v-for="(attachment, index) of messageDetail.MessageAttachment"
              :key="index"
            >
              <div class="d-flex">
                <IconSet
                 :icon="attachment.FileType !== 'pdf'? 'pdf': attachment.FileType"
                  :class="{ onHoverColIcon: isMobileStreet === true }"
                >
                </IconSet>
                <div class="message-attachment-description">
                  <span class="font-weight-bold">{{ attachment.Title }}</span>
                  <p>{{get_upper_case(attachment.FileType)}} - {{attachment.FileSize}} MB</p>
                </div>
              </div>
              <div
                class="message-attachment-download"
                @click="downloadAttachment(attachment)"
              >
                <button class="button">
                  <IconSet icon="download"> </IconSet> {{labels.DocumentDownload}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="message-signature-wrapper mt-3 mb-3" v-if="messageDetail.MessageContact.Text || messageDetail.MessageContact.Email  || messageDetail.MessageContact.Phone ">
          <div class="message-signature">
            <div class="message-signature-inner">
              <div v-html="messageDetail.MessageContact.Text"></div>
              <!-- <div>Musterstraße 3, 9500 Villach</div> -->
              <div>
                <span class="message-signature-email"  :class="messageDetail.MessageContact.Email && messageDetail.MessageContact.Phone  ? 'email-border': ''" v-if="messageDetail.MessageContact.Email"
                  ><IconSet icon="signaturemail"> </IconSet>
                  {{ messageDetail.MessageContact.Email }}</span
                >
                <span class="message-signature-phone" :class="messageDetail.MessageContact.Email && messageDetail.MessageContact.Phone  ? 'phone-margin': ''" v-if="messageDetail.MessageContact.Phone"
                  ><IconSet icon="signaturephone"> </IconSet
                  >{{ messageDetail.MessageContact.Phone }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
       <div class="message-empty marginSpace" v-if="filteredMessageList.length<=0">
      <div class="message-empty-inner">
        <IconSet icon="emptymessage" state="on"></IconSet>
        <p>
          {{labels.NoFilterFound}}
        </p>
      </div>
    </div>
    </div>
    <div class="message-empty" v-if="filterList.length <= 0 && isListUpdated">
      <div class="message-empty-inner">
        <IconSet icon="emptymessage" state="on"></IconSet>
        <p>
          {{labels.NoFilterFound}}
        </p>
        <button
          class="button"
          v-if="messageList.length && filterList.length == 0"
          @click="clearFilter"
        >
          <IconSet icon="filter"></IconSet> {{ labels.FilterClear }}
        </button>
      </div>
    </div>
  </Page>
</template>

<script>

import Page from "@/structure/Page.vue";
import ViewTitle from "@/components/ViewTitle.vue";

import Toggle from "@/structure/Toggle.vue";
import ToggleMobile from "@/components/ToggleMobile.vue";
import ToggleFilterButton from "@/components/ToggleFilterButton.vue";
import IconSet from "@/structure/IconSet.vue";

import moment from "moment";

import { vcm } from "@/mixins/vcm";
import { vvm } from "@/mixins/vvm";
import { mapActions } from "vuex";
import axios from "axios";
import { attachmenturl } from "@/services/messageService";
export default {
  mixins: [vcm, vvm],
  name: "Message",
  components: {
    Page,
    ViewTitle,

    IconSet,
    Toggle,
    ToggleMobile,
    ToggleFilterButton,
  },
  data() {
    return {
      labels: {
        AppliedFilter:this.get_scope_translation("Referencenumber", "label"),
        Art:this.get_scope_translation("Art", "label"),
        BackToList:this.get_scope_translation("BackToList", "label"),
        Category:this.get_scope_translation("Category", "label"),
        DocumentDownload:this.get_scope_translation("DocumentDownload", "label"),
        FilterOpen:this.get_scope_translation("FilterOpen", "label"),
        FilterClose:this.get_scope_translation("FilterClose", "label"),
        FilterClear:this.get_scope_translation("FilterClear", "label"),
        Info:this.get_scope_translation("Info", "label"),
        NoFilterFound:this.get_scope_translation("NoFilterFound", "label"),
        NoMessageData:this.get_scope_translation("NoMessageData", "label"),
        Read:this.get_scope_translation("Read", "label"),
        Search:this.get_scope_translation("Search", "label"),
        Unread:this.get_scope_translation("Unread", "label"),      
        Referencenumber: this.get_scope_translation("Referencenumber", "label"),
        
        Title: "Nachrichten",
        Description: "Hier finden Sie nicht nachweislich zugestellte Dokumente Ihrer Gemeinde.",

        Bookings: this.get_scope_translation("Bookings", "label"),
        Arrearage: this.get_scope_translation("Arrearage", "label"),
        Deposit: this.get_scope_translation("Deposit", "label"),
        Export: this.get_scope_translation("Export", "label"),
        Spent: this.get_scope_translation("Spent", "label"),
        ShowFilter: this.get_scope_translation("ShowFilter", "label"),
        HideFilter: this.get_scope_translation("HideFilter", "label"),
        Categories: this.get_scope_translation("Categories", "label"),
        RemoveAllFilters: this.get_scope_translation(
          "RemoveAllFilters",
          "label"
        ),
        SelectedFilters: this.get_scope_translation("SelectedFilters", "label"),
        Filter: this.get_scope_translation("Filter", "label"),
        From: this.get_scope_translation("From", "label"),
        To: this.get_scope_translation("To", "label"),
        Emblem: this.get_translation("App", "Emblem", "label"),
        Sum: this.get_translation("App", "Sum", "label"),
        FilterBtnText: this.get_scope_translation("ApplyFilter", "label"),
        RemoveFilter: this.get_scope_translation("Filter löSchen", "label"),
        NoBooking: this.get_scope_translation("NoBooking", "label"),
        NoDataFound: this.get_scope_translation("NoDataFound", "label"),
        Date: this.get_scope_translation("NoDataFound", "label"),
      },
      filterDate: {
        From: moment().format("YYYY-MM-DD"),
        To: moment().format("YYYY-MM-DD")
      },
      maxDate: null,
      minDate: null,
      isHover: false,
      allIsOpen: false,
      isMobile: false,
      isTrue: false,
      isDefaultDate: true,
      onLoadDate: false,
      isClearFilter: false,
      defaultSelectedDate: {
        From: "",
        To: "",
      },
      isMobileStreet: false,
      isMobileSerivceType: false,
      isMobileDate: false,
      islegendCollection: false,
      selectedItem: [],
      selectedDate: {},
      selectedAddress: [],
      legendCollection: [],
      selectedServiceGroupType: null,
      mobileToggleOpen: true,
      showMessageDetail: false,
      messageTypes: [
        {
          Name: "Gelesen",
          Read: true,
        },
        { Name: "Ungelesen", Read: false },
      ],
      isArchivePage: false,
      selectedMessage: {},
      searchQuery: "",
      filterList: [],
    };
  },
  async beforeCreate() {
    try {
      let Url="";
      if (this.$route.name == "archiv") {
        Url = process.env.VUE_APP_BASE_URL + "/v1/pages/archiv";
      }
      else{
      Url = process.env.VUE_APP_BASE_URL + "/v1/pages/message";
      }
      const response = await axios.get(Url, {
        params: { language: `${this.$root.config.localization.language}` },
      });

      if (response.data.responseCode === "200" && response.data.response) {
        this.labels.Title = response.data.response.Title;
        this.labels.Description = response.data.response.Description;
      }
    } catch (e) {
      console.log("Error : " + e);
    }
  },
  created: function () {
    if (this.$route.name == "archiv") {
      this.isArchivePage = true;
      this.SetIsArchivepage(true);
    }
    else {
      this.SetIsArchivepage(false);
    }
    window.addEventListener("resize", this.windowResizeHandler);
    
  },
  async mounted() {
    this.getmessageCounts();
    this.windowResizeHandler();
    this.$root.set_document_title("route", "", "");

    // calcualte min and max date
    this.onLoadDate = true;
    this.islegendCollection = true;
    setTimeout(() => this.addAriaLabel(), 1100);
    await this.getMessageList();
    const FiledLabels = this.$root.get_label_fields(
      `${this.$root.config.localization.language}`,
      "Message"
    );
    Object.assign(this.labels, FiledLabels);
    this.messageTypes[0].Name=this.labels.Read;
     this.messageTypes[1].Name=this.labels.Unread;

  },
  destroyed() {
    window.removeEventListener("resize", this.windowResizeHandler);
    this.setMessageListEmpty();
  },

  computed: {
    style() {
      return {
        position: "relative",
      };
    },
    isListUpdated() {
      return this.$store.state.message.isListUpdated;
    },
    selectItem() {
      return JSON.parse(JSON.stringify(this.selectedItem));
    },
    serviceGroupCollection() {
      return { ...this.$store.state.message.serviceGroupCollections };
    },
    messageDetail() {
      return { ...this.$store.state.message.messageDetails };
    },
    messageList() {
      return this.$store.state.message.messageList;
    },
    filteredMessageList() {
      const data = this.filterList;
      return data.filter((message) => {
        const lowercaseQuery = this.searchQuery.toLowerCase();
        const lowercaseTitle = message.messages.MessageTitle.toLowerCase();
        const lowercaseSender = message.messages.MessageSender.toLowerCase();

        // Search within attachment titles
        const includesAttachment = message.messages.MessageAttachment.some(
          (attachment) =>
            attachment.Title.toLowerCase().includes(lowercaseQuery)
        );

        return (
          lowercaseTitle.includes(lowercaseQuery) ||
          lowercaseSender.includes(lowercaseQuery) ||
          includesAttachment
        );
      });
    },
    bookingList() {
      return this.$store.state.booking.bookingList;
    },
    bookingCollection() {
      return this.$store.state.booking.bookingCollection;
    },
    addressList() {
      return this.$store.state.booking.bookingAddress;
    },
    selectedFilterItem: {
      get() {
        return this.$store.state.booking.selectedFilterItem;
      },
      set(item) {
        this.$store.commit("booking/SET_SELECTED_FILTER_ITEM", item);
      },
    },
    fromDate: {
      get() {
        return this.filterDate.From;
      },
      set(value) {
        this.filterDate.From = value;
        this.minDate = value;
        this.isDefaultDate = false;
        this.dateSelectFilter();
        this.onFilterHandler();
      },
    },
    toDate: {
      get() {
        return this.filterDate.To;
      },
      set(value) {
        this.filterDate.To = value;
        this.maxDate = value;
        this.isDefaultDate = false;
        this.dateSelectFilter();
        this.onFilterHandler();
      },
    },
  },
  watch: {
    bookingCollection: {
      handler: function (newVal) {
        if (newVal.length !== 0) {
          this.filterDateRange(newVal);
          this.addresswithBookingAddress();
        }
      },
    },
    messageList: {
      handler: function (newVal, oldVal) {
        this.filterList = newVal;
        if(newVal.length) {
          if(this.selectedItem.length==0)
          {
          this.minDate = newVal[newVal.length-1].messages.MessageDate;
          this.maxDate = newVal[0].messages.MessageDate;
          this.filterDate.To = newVal[0].messages.MessageDate;
          this.filterDate.From = newVal[newVal.length-1].messages.MessageDate;
          this.defaultSelectedDate.From = newVal[newVal.length-1].messages.MessageDate;
          this.defaultSelectedDate.To = newVal[0].messages.MessageDate;
          }
          else{
            this.onFilterHandler();
          }
        }
        //by default first message will be selected
        if (!this.isMobile && this.filterList.length && oldVal.length == 0) {
          this.getMessageDetails(this.filterList[0]);
        }
      },
    },
    selectedItem: {
      handler: async function () {
        this.onFilterHandler();
      },
    },
  },

  methods: {
    ...mapActions({
      getMessage: "message/getMessageDetail",
      getMessageList: "message/getMessageList",
      getmessageCounts: "message/getMessageCount",
      readMessage: "message/getMessageRead",
      setMessageListEmpty: "message/SetEmptyMessageList",
      SetIsArchivepage: "message/SetIsArchivepage"

    }),
    addAriaLabel: function () {
      var buttons = document.getElementsByTagName("button");
      if (typeof buttons != "undefined" && buttons.length > 0) {
        Array.from(buttons).forEach((v) => {
          if (!v.attributes["aria-label"]) {
            v.setAttribute("aria-label", "Auswählen");
          }
        });
      }
    },
    toggle_mobilecontent() {
      if (this.mobileToggleOpen) {
        this.mobileToggleOpen = false;
      } else {
        this.mobileToggleOpen = true;
      }
    },
    filterDateRange(bookingCollection = this.bookingCollection) {
      if (Array.isArray(bookingCollection) && bookingCollection.length) {
        let fromDateArr = new Set();
        for (let date of bookingCollection) {
          for (let booking of date.Bookings) {
            fromDateArr.add(booking.BookingDate);
          }
        }
        this.getDateRange(fromDateArr);
      }
    },
    onClickLegend(item) {
      this.isClearFilter = true;
      this.islegendCollection = false;
      this.selectedItem = [];
      this.selectedDate = {};
      this.filterDateRange();
      if (this.selectedServiceGroupType === item.UUID) {
        this.selectedServiceGroupType = null;
        this.$store.commit("booking/SET_LEGEND_BOOKING_LIST", false);
      } else {
        this.selectedServiceGroupType = item.UUID;
        this.selectedItem = this.serviceGroupCollection.filter(
          (ServiceTypeGroup) => ServiceTypeGroup.UUID === item.UUID
        );
        this.$store.commit("booking/SET_LEGEND_BOOKING_LIST", item);
      }
    },
    removeSelectedItem(item, index) {
      if ("from" in item) {
        this.selectedDate = {};
        this.isDefaultDate = true;
        this.filterDateRange();
      }
      if ("Name" in item) {
        this.selectedServiceGroupType = null;
      }
      this.selectedItem.splice(index, 1);
      this.onFilterHandler();
    },
    addresswithBookingAddress() {
      let addressIdObj = {};
      let addressIdWithBookingObj = {};
      let matchBookingAddress = {};
      for (let address of this.addressList) {
        addressIdObj[address.AddressId] = address;
      }
      for (let bookingObj of this.bookingList) {
        for (let booking of bookingObj.Bookings) {
          addressIdWithBookingObj[booking.Address.AddressId] = booking.Address;
        }
      }

      for (let address in addressIdObj) {
        if (addressIdWithBookingObj[address]) {
          matchBookingAddress[address] = addressIdWithBookingObj[address];
        }
      }
      for (let address of this.addressList) {
        address.Bookings = [];
        if (matchBookingAddress[address.AddressId]) {
          address.Bookings.push(matchBookingAddress[address.AddressId]);
        }
      }
    },
    async getMessageDetails(message) {
      if (this.isMobile) {
        this.switchMessageDetail();
      }
      this.selectedMessage = message.messages;
      this.getMessage(message.messages.UUID);
      if (!message.messages.MessageStatus.Read) {
         message.messages.MessageStatus.Read = true;
       await this.readMessage(message.messages.UUID);
      }
    },
    switchMessageDetail() {
      if (this.isMobile) {
        this.showMessageDetail = !this.showMessageDetail;
      } else {
        this.showMessageDetail = true;
      }
    },
    checkDownload(attachment) {
      let count = 0;
      attachment.forEach((attach) => {
        if (attach.Downloaded) {
          count++;
        }
      });
      if (attachment.length == count) {
        return true;
      } else {
        return false;
      }
    },
    downloadAttachment(attachment) {
      let obj = {
        messageid: this.messageDetail.UUID,
        fileuuid: attachment.UUID,
      };
      attachmenturl(obj).then((data) => {
        const link = document.createElement("a");
        link.href = data.response;
        link.download = attachment.Title + "." + attachment.FileType;
        link.click();
        this.getMessageList();
        this.onFilterHandler();
      });
    },

    windowResizeHandler() {
      // your code for handling resize...
      this.isMobile = window.innerWidth < 768 ? true : false;
      if (this.isMobile) {
        this.showMessageDetail = false;
      } else {
        this.showMessageDetail = true;
      }
    },
    allOpenHandler() {
      this.allIsOpen = !this.allIsOpen;
    },

    getDateRange(fromDates) {
      let dates = [...fromDates];
      dates = dates.map((date) => new Date(date));
      var max = new Date(Math.max.apply(null, dates));
      var min = new Date(Math.min.apply(null, dates));
      this.defaultSelectedDate.From = min;
      this.defaultSelectedDate.To = max;
      if (this.onLoadDate) {
        this.filterDate.From = min;
        this.filterDate.To = max;
        // this.minDate = min;
        // this.maxDate = max;
      }
    },

    clearFilter() {
      this.isClearFilter = true;
      if (this.selectedItem.length) {
        this.selectedItem = [];
      }
      this.selectedDate = {};
      this.isDefaultDate = true;
      this.isMobileStreet = false;
      this.isMobileSerivceType = false;
      this.isMobileDate = false;
      this.minDate = this.defaultSelectedDate.From;
      this.maxDate = this.defaultSelectedDate.To;
      this.filterDate.From = this.defaultSelectedDate.From;
      this.filterDate.To = this.defaultSelectedDate.To;
      this.filterDateRange();
    },

    onFilterHandler() {
      if (!this.selectedItem.length) {
        this.clearFilter();
      }

      let filterObj = {
        categories: [],
        messageTypes: [],
        date: [],
      };

      let messageList = this.messageList;

      this.islegendCollection = false;
      for (let item of this.selectedItem) {
        if ("Name" in item && !("UUID" in item)) {
          filterObj.messageTypes.push(item);
        }
        if ("Name" in item && "UUID" in item) {
          filterObj.categories.push(item);
        }
        if ("from" in item) {
          filterObj.date.push(item);
          // this.defaultSelectedDate = {
          //   From: item.from,
          //   To: item.to,
          // };
        }
      }
      let categoryFilter = filterObj.categories.map(
        (category) => category.UUID
      );
      let typeFilter = filterObj.messageTypes.map((type) => type.Read);
      let dateFilter = filterObj.date;
      this.filterList = [];
      messageList.forEach((message) => {
        let messageDate = moment(message.messages.MessageDate).format('YYYY-MM-DD');
        if (
          (categoryFilter.length == 0 ||
            categoryFilter.includes(message.messages.ServiceTypeGroup.UUID)) &&
          (typeFilter.length == 0 ||
            typeFilter.includes(message.messages.MessageStatus.Read)) && 
          (dateFilter.length == 0 || (moment(messageDate).isSameOrAfter(dateFilter[0].from) && moment(messageDate).isSameOrBefore(dateFilter[0].to)))
        ) {
          this.filterList.push(message);
        }
      });
    },

    dateSelectFilter() {
      this.islegendCollection = false;
      if (!this.selectedItem.length || !this.selectedDate.from) {
        this.selectedDate = {
          from: moment(this.filterDate.From).format("YYYY-MM-DD"),
          to: moment(this.filterDate.To).format("YYYY-MM-DD"),
        };
        this.selectedItem = [...this.selectedItem, this.selectedDate];
        this.$store.commit("booking/SET_BOOKING_DATE", this.selectedDate);
      } else {
        for (let selectItem of this.selectedItem) {
          if (selectItem.from) {
            selectItem.from = this.filterDate.From;
            selectItem.to = this.filterDate.To;
            this.$store.commit("booking/SET_BOOKING_DATE", selectItem);
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_var.scss";
// Message page css
.mobile-filter-toggle {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  a {
    text-decoration: none;
    color: #121212;
  }
}
.icon-class{
  height:16px;
}
.message-title {
  margin-bottom: 13px !important;
}
.filterHeading .vgs-icon-set svg {
  width: auto;
  height: auto;
}
.outerdiv {
  display: flex;
  border-bottom: 1px solid #C7D0DB;
}

.pdiv {
  margin-left: 26px;
}
.message-details {
  margin-bottom: 19px;
  &-image {
    width: 33px;
  }
}
.message-list-back {
  margin-bottom: 5px;
   cursor: pointer;
}
.message-info {
  margin-left: 10px;
  margin-top: -4px;
}
.message-right-info {
  font-size: 12px;
  color: #56676c;
}
.message-ref-number {
  border-right: 1px solid #56676c;
  margin-right: 3px;
padding-right: 2px;
}

@media (min-width: 991px) and (max-width: 1350px) {
.message-right {
  position: relative;
 text-align: right;
}
}

.pdiv1 {
  font-size: 14px;
}

.pdiv2 {
  font-size: 16px;
  margin-bottom: 0px;
}
.message-description {
  margin-top: 19px;
  p {
    margin-bottom: 0;
  }
}
.left-column {
  padding-top: 21px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  width: 40%;
}

.right-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 30px;
  padding-top: 21px;
  width: 60%;
}

.sidecontent {
  text-align: right;
  font-size: 12px;
  margin-left: 80px;
}
.button {
  background-color: #ffde00;
  border: none;
  border-radius: 3px;
  margin: 0 !important;
  padding: 10px !important;
  height: auto !important;
}
.message-details-image{
  
  image-rendering: auto;
}
.boxdiv {
  height: auto;
  border-bottom: 1px solid #ccc;
  padding: 1px;
  font-size: 7px;
  line-height: 14px;
  color: #000000;
  font-weight: 400;
  font-family: "Nunito Sans";
  background-color: #ffde00;
}

.message-attachment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c7d0db;
  padding: 10px 10px 10px 13px;
  margin-top: 10px;
  &-wrapper {
    margin-top: 20px;
  }
  &-description {
    margin-left: 10px;
    margin-top: -4px;
    p {
      margin: 0;
      color: #59696e;
      font-size: 12px;
    }
  }
}
.message-signature {
  background: #ebf0f4 0% 0% no-repeat padding-box;
  padding: 11px;
  font-size: 12px;
  color: #56676c;
  &-email {
    padding-right: 5px;
   
     margin-left:-3px;
  }
  &-phone{
    margin-left: -4px;
  }
}
.phone-margin{
  margin-left: 0px;
}
.email-border{
   border-right: 1px solid #56676c;
}
.message-item {
  &-wrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    cursor: pointer;
  }
  &-unread {
    position: absolute;
    top: 20px;
  }
  &-wrapper.active {
    border-left: 4px solid #ffde00;
    background: rgba(255, 222, 0, 0.06);
  }
  &-inner {
    padding: 20px 15px 20px 19px;
  }
  &-date {
    font-size: 12px;
    color: #697982;
  }
  &-description {
    margin-top: 5px;
  }
}

.marginSpace{
  margin: auto;
  margin-top:7%;
}
.message-empty {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;

  &-inner {
    text-align: center;
    width: 310px;
    p {
      margin: 15px 0 0 0;
    }
    .button {
      margin-top: 20px !important;
    }
  }
}
@media (max-width: 340px) {
  .message-empty {

  &-inner {
    
    width: auto;
    p {
      margin: 15px 0 10px 0;
    }
  }
}
}
.bold-text {
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  font-weight: 400;
  font-family: "Nunito Sans";
}

.normal-text {
  font-size: 12px;
  line-height: 14px;
  color: #697982;
  font-weight: 400;
  font-family: "Nunito Sans";
  margin: 0;
}
.search-container {
  padding-right: 10px;
  margin-bottom: 20px;
}
.searchInput{
  font-size: 14px;
}
.input-group {
  border: 1px solid #cdcdcd;
  border-radius: 4px;
}

.input-group-prepend .input-group-text {
  border: none;
  background: none;
  padding-left: 9px;
  padding-right: 10px;
}

.input-group > .form-control {
  border: none;
  margin-top: 7px;
  margin-bottom: 7px;
}

.input-group > .form-control:focus {
  box-shadow: none;
}

.search-button {
  background-color: #ccc;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 2px;
}

.box {
  background-color: #e5eaf0;
  display: flex;
  font-family: "Nunito Sans", sans-serif;
}

.column {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ffffff;
  font-size: 18px;
  color: #000000;
}

.icon {
  font-size: 20px;
  margin-bottom: 5px;
}

.row {
  display: flex;
  flex-direction: row;
}
//Account page css
.vcs-date-box {
  width: 130px;
}
.vcs-check-filter {
  margin-top: 6px;
  margin-bottom: 24px;
  margin-left: 6px;
}
.vcs-check-filter:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.vcs-check-filter .vcf-box {
  position: relative;
  float: left;
  width: 18px;
  height: 18px;
  border: 1px solid #d7d7d7;
  background-color: #ffffff;
  margin-top: 6px;
  margin-right: 10px;
  padding: 0;
}
.vcs-check-filter .vcf-box .cfb-tray {
  position: absolute;
  top: -8px;
  left: -7px;
}
.vcs-check-filter .vcf-box .cfb-tray .b-icon {
  color: $highlightFontColor;
  font-size: 2rem;
  margin: 0 0 0 0;
}
.vcs-check-filter .vcf-emblem {
  float: left;
  margin-right: 6px;
}
.vcs-check-filter .vcf-title {
  float: left;
  word-break: break-word;
}
.vcs-check-filter .vcf-title .cft-title {
  font-size: 1rem;
  line-height: 1.188rem;
  font-weight: 400;
  display: block;
  margin-top: 6px;
}
.vcs-check-filter .vcf-title .cft-name {
  font-size: 0.813rem;
  line-height: 1rem;
  font-weight: 700;
  display: block;
}
.vcs-check-filter .vcf-title .cft-type {
  font-size: 0.813rem;
  line-height: 1rem;
  font-weight: 400;
  display: block;
}

.vcs-check-filter .vcf-title.input-set {
  min-width: 40px;
  margin-left: 2px;
}

.vcs-check-filter .vcf-input {
  float: left;
}

.vcs-booking-line {
  font-size: 0.875rem;
  line-height: 1rem;
}

.vcs-column.group {
  width: 22% !important;
}
.toggle-content.mobileFilter .vcs-column {
  width: 100% !important;
}
.vcs-column.timespan {
  width: 240px !important;
}
.vcs-column.filter {
  width: calc(100% - 660px) !important;
}

.vcs-column.highlight {
  border-top: 2px solid transparent;
}

.vcs-column.highlight:hover {
  border-top: 2px solid $highlightColor;
  background-color: #dfeaf0;
}

.vcs-column.line {
  padding-top: 19px;
  border-right: 1px solid #ffffff;
}
.vcs-column .vgs-icon-set {
  margin-right: 6px;
  margin-left: 6px;
}
.filter-section {
  display: flex;
  flex-direction: column;
  width: 18rem;
  justify-content: space-between;
  padding-top: 14.5px;
  padding-bottom: 20px !important;
}
.form-control.form-control-sm {
  font-size: 14px;
  color: #0a0a0a;
}

.filter-section .address {
  width: 100%;
}

div#checkbox-group .custom-control .custom-control-label {
  font-size: initial !important;
}

.filter-button:hover {
  font-size: inherit;
}

.vcs-filter-info {
  padding: 16px 0 20px 21px;
}
.vcs-filter-info .vfi-title {
  font-size: 1rem;
  line-height: 1.188rem;
  font-weight: 700;
}
.vcs-filter-info .vfi-link {
  font-size: 1rem;
  line-height: 1.188rem;
  margin-left: 16px;
  margin-right: 14px;
}
.vcs-filter-info .vfi-link .fil-mark {
  color: $highlightFontColor;
  font-weight: 700;
  margin-right: 5px;
  cursor: pointer;
}

.vcs-filter-section {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  padding-left: 1.2rem;
}
.vcs-filter-section .vfi-title {
  font-size: 0.875rem;
  line-height: 1.188rem;
  font-weight: 700;
}
.vcs-filter-section .vfi-link {
  margin-bottom: 0.875rem;
  font-size: 14px;
  line-height: 1.188rem;
}
.vcs-filter-section .vfi-link .fil-mark {
  color: $highlightFontColor;
  font-weight: 700;
  margin-right: 5px;
  cursor: pointer;
}

.col.UnPaid {
  color: $highlightFontColor;
}

.vcs-text {
  font-size: 0.875rem;
  line-height: 1.188rem;
}
.vcs-type {
  font-size: 0.75rem;
  line-height: 1rem;
}
.vcs-amount {
  font-size: 0.775rem;
  line-height: 1.2rem;
}
.vcs-sum {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.vcs-account-state {
  background-color: $highlightFontBGColor;
  color: $highlightFontColor;
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  text-align: left;
}
.vcs-account-state .vas-amount {
  float: right;
  margin: 0 0.75rem 0 0;
}

.vcs-left {
  width: calc(100% - 300px);
  margin-right: 20px;
}
.vcs-right {
  text-align: center;
}
.graph-right {
  position: relative;
}

.vcs-chart {
  margin: 2rem auto 0 auto;
}

.vcs-legend {
  display: flex;
  justify-content: center;
  margin: 1.5rem 0 0 0;
  cursor: pointer;
}
.vcs-legend:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.vcs-legend .csl-item {
  float: left;
  display: table-cell;
  width: 22px;
  height: 35px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  margin: 0 6px 0 6px;
}
.vcs-legend .csl-item img.sli-img {
  width: 22px;
  vertical-align: bottom;
}
.vfi-type {
  font-weight: bold;
  margin-left: 0.25rem;
  word-break: break-word;
}
.vfi-address {
  padding-left: 12px;
}

.csv-file-icon {
  filter: invert(13%) sepia(91%) saturate(4054%) hue-rotate(334deg)
    brightness(93%) contrast(92%);
}

h2 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
}
.vcs-export {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  text-align: right;
  font-size: 0.875rem;
  line-height: 1.688rem;
  color: $highlightFontColor;
  padding-right: 0.3rem;
}

.mobileView .vcs-title {
  padding: 0;
}
h3 {
  width: 100%;
  text-align: right;
  margin-bottom: 0.5rem;
  font-size: 14px;
  line-height: 1.688rem;
  font-weight: 700;
  text-align: left;
}
.booking-description {
  display: flex;
  flex-direction: column;
}
.filterHeading .vcs-column {
  padding-top: 14px;
  padding-bottom: 14px;
  align-items: center;
  display: flex;
}

.bookingListHeading,
.toggle-handle {
  padding: 0px;
}
.bookingListHeading,
.filterHeading .vcs-column {
  font-size: 18px;
  cursor: pointer;
}
.timespan .cft-title {
  font-weight: bold !important;
}
button {
  font-size: 16px;
  padding: 0px !important;
  height: 45px;
}
.filter-header-text {
  display: flex;
  align-items: center;
}
.filter-header-text .vgs-icon-set {
  margin-right: 0px !important;
  margin-left: 0px !important;
  width: 20px;
}
.filter-header-text > span {
  padding-left: 10px !important;
}
.filterr-top {
  justify-content: flex-end;
  align-items: center;
}
.bookingRowHeading .threeInOne {
  width: 55%;
  // word-break: break-all;
}
.booking-description .vcs-booking-line {
  display: flex;
  align-items: center;
}

.bookingRowHeading .threeInOne .vcs-type {
  font-size: 12px !important;
  color: #757575 !important;
}
.bookingRowHeading .threeInOne .vcs-title {
  color: #000000;
}

.onHoverColIcon {
  fill: $primaryColor;
}
.onHoverColText {
  color: $primaryColor;
}

.graph-text-center {
  position: absolute;
  top: 13rem;
  right: 0;
  left: 0;
  z-index: -9999;
}

.empty-vcs-left {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 100%;
  text-align: center;
}
.empty-vcs-left-mobile {
  display: flex;
  justify-content: center;
  margin-top: 20%;
}

.ClearButton {
  cursor: pointer;
  padding-right: 7px;
}

.vcs-filter-section span:not(:first-child),
.vcs-filter-section span:not(:last-child) {
  margin-bottom: 20px;
}

.booking-section:nth-child(even) {
  align-items: center;
  background-color: white;
}

.booking-row {
  align-items: center !important;
}

.line1 {
  height: 1.5rem;
}

.line2 {
  font-size: 12px;
  color: #6b6b6b;
}
.vcs-booking-line > .right {
  display: flex;
  flex-direction: column;
}

.graph-filter-deactivate-icon > .vgs-icon-set {
  -webkit-filter: grayscale(1);
  -webkit-filter: grayscale(100%);
  filter: gray;
  filter: grayscale(100%);
}
.graph-filter-deactivate-icon {
  border-color: gray !important;
}
.display-none-heading {
  display: none;
}
@media (min-width: 768px) {
  .outerdiv {
    min-height: 500px;
  }
  .message-list-container {
    height: 445px;
    overflow-y: auto;
    scrollbar-width: 5px !important;
    scrollbar-color: #C7D0DB #ebf0f4;
  }

  .message-list-container::-webkit-scrollbar {
    width: 3px;
  }

  .message-list-container::-webkit-scrollbar-track {
    background: #ebf0f4;
  }

  .message-list-container::-webkit-scrollbar-thumb {
    background-color: #C7D0DB;
    border-radius: 20px;
  }
}
@media (max-width: 767px) {
  .outerdiv {
    flex-direction: column;
  }
  .left-column {
    width: 100%;
    border: none;
  }
  .marginSpace{
  
  margin-top:auto;
}
  .right-column {
    margin: 0px;
    width: 100%;
  }
  .search-container {
    padding-right: 0px;
  }
  .message {
    &-details {
      flex-direction: column;
    }
    &-right-info {
      margin-left: 42px;
      margin-top: 5px;
      &-detail {
        justify-content: flex-start !important;
      }
    }
    &-attachment {
      flex-direction: column;
      align-items: flex-start;
      &-download {
        margin-top: 10px;
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
    &-signature-wrapper {
      margin-top: 30px !important;
    }
  }
  .filter-section {
    padding-top: 21px;
  }
  .line1 {
    height: 40px;
  }
  .vcs-booking-line > .right {
    display: block;
  }
  .mobileView .row > div {
    padding: 0 5px;
    max-width: 20%;
    flex: unset;
  }
  .mobileView .vcs-amount {
    font-size: 12px !important;
  }

  .toggle-tray {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vcs-right {
    width: 100%;
  }
  .vcs-column.timespan[data-v-7e0221cf] {
    width: 100% !important;
  }
  .vcs-left {
    width: 100%;
    margin-right: unset;
  }
  .vcs-column.filter {
    width: unset;
  }
  .vgs-toggle.box .toggle-title .toggle-handle {
    padding: unset;
  }
  .vcs-amount.mobileHide {
    display: none;
  }
  .vcs-booking-line > .left {
    min-width: 6.7rem;
  }
  .vfi-address {
    padding-left: 2.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .message {
    &-details {
      flex-direction: column;
    }
    &-right-info {
      margin-left: 42px;
      margin-top: 5px;
      &-detail {
        justify-content: flex-start !important;
      }
    }
  }
  .vcs-booking-line > .left {
    min-width: 7.7rem;
  }

  .line1 {
    height: 0.6rem;
  }

  .vcs-check-filter .vcf-title {
    width: max-content;
  }
  .toggle-content.mobileFilter .vcs-column {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
@media (max-width: 2500px) and (min-width: 1521px) {
  .graph-right {
    width: 28% !important;
  }
}

*:focus {
  outline: none;
}

.description {
  font-size: 16px;
  margin-bottom: 20px;
}
</style>
